//import { useState, useEffect } from "react";

//const ANIMALS = ["bird", "cat", "dog", "rabbit", "reptile"];

const Module2 = () => {
  return (
    <div className="module2">
      <h3>DIY Dome Plans</h3>
    </div>
  );
};

export default Module2;
