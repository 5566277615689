//import { useState, useEffect } from "react";

//const ANIMALS = ["bird", "cat", "dog", "rabbit", "reptile"];

const Module3 = () => {
  return (
    <div className="module3">
      <h3>DIY Dome Animations</h3>
    </div>
  );
};

export default Module3;
