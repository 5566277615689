import React from "react";
import ReactDOM from "react-dom";
import Module1 from "./Module1";
import Module2 from "./Module2";
import Module3 from "./Module3";

const App = () => {
  return (
    <div id="diydome-app">
      <h1>DIY Dome</h1>
      <Module1 />
      <Module2 />
      <Module3 />
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
